.modal-content {
  width: 80%; /* Adjust this to your desired width */
  border-radius: 25px !important; /* Adjust this to your desired roundness */
  margin: auto;
}

.fade {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.5);
}


